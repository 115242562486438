import * as React from "react"

import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import CallToActionButton from "../components/CallToActionButton"

import "../styles/about-us.styl"

const aboutUs = () => (
  <Layout>
    <Seo title="Acerca de nosotros" />
    <section className="About">
      <h1 className="about__title">Acerca de nosotros</h1>
      <div className="imagephoton">
        <StaticImage
          src="../images/aboutUsImage.png"
          alt="esfera y texto PHOTON ENERGIAS SUSTENTABLES"
          objectFit="contain"
          className="imagephoton__img"
          loading="lazy"
        />
      </div>
      <p className="about__text">
        En PHOTON ofrecemos productos para aprovechar las
        <strong className="green-text"> Energías Sustentables</strong> y obtener
        beneficios económicos y ecológicos de manera responsable. <br />
        Los mecanismos para el aprovechamiento de la energía solar son
        mínimamente invasivos y se adaptan plenamente a nuestro estilo de vida.
        Además, la energía solar es la fuente de energía más potente al alcance
        del público, fácil de usar y ofreciendo una increíble relación
        costo-beneficio.
        <br />
      </p>
      <h2 className="about__secondary-title">CALIDAD</h2>
      <p className="about__text">
        Nuestros productos están fabricados con la más alta calidad y estándares
        internacionales, haciendo de ellos la mejor opción en el mercado.
      </p>
      <p className="about__text">
        Nuestro método de instalación esta basado en la mejora continua de
        nuestros procesos internos y se adapta a todas las condiciones. Tenemos
        una solución para cada proyecto, considerando todas las variables de
        cada cliente, le hacemos un traje a la medida.
      </p>
      <p className="about__text">
        Ofrecemos soluciones termosolares y fotovoltaicas a través de la
        integración de proyectos de ahorro de energía y comercialización de
        productos ecológicos que permiten el uso eficiente de luz, agua y gas
        mediante tecnologías sustentables.
      </p>
      <div className="about__history history">
        <div className="wrapper-flex">
          <div className="history__img">
            <StaticImage
              alt="rounded blue ball ant PHOTON word"
              src="../images/logos/photon-logo-blue.jpg"
              objectFit="contain"
              quality="100"
            />
          </div>
          <h2 className="history__title">en el tiempo...</h2>
        </div>
        <p className="history__text">
          Llegamos en 2014 con la venta e instalacion de calentadores solares y
          fuimos creciendo. Ya para 2015 ingresamos en el mercado Fotovoltaico y
          venta de equipos de Eco-Tecnologías para constructoras y asi nos hemos
          ido asentando en el mercado potosino y del bajío.
        </p>
        <p className="history__text">
          En 2019 nos establecimos en un nuevo punto de venta y oficina en una
          ubicación estratégica para nuestro continuo desarrollo, en Genaro
          Codina #180-5, Colonia Jardines del Estadio, frente a CFE y a un
          costado de FIDE, esto con el fin de generar mejor sinergia con ambas
          instituciones y ofrecer a nuestros clientes la mejor experiencia,
          seguridad y calidad.
        </p>
        <p className="history__text">
          En adelante nuestro plan continuar creciendo para consolidarnos como
          la mejor empresa de venta e instalacion de Energías Sustentables de
          San Luis Potosí y del Bajío.
        </p>
      </div>
    </section>
    <CallToActionButton />
  </Layout>
)

export default aboutUs
